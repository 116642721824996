package com.peerke.outdoorpuzzlegame.client.common

import kotlin.js.JsName

enum class LogLevel {
    DEBUG, WARN, ERROR
}

internal expect fun writeLogMessage(message: String, logLevel: LogLevel)


@JsName("logDebug")
fun logDebug(message: String) = writeLogMessage(message, LogLevel.DEBUG)

@JsName("logWarn")
fun logWarn(message: String) = writeLogMessage(message, LogLevel.WARN)

@JsName("logError")
fun logError(message: String) = writeLogMessage(message, LogLevel.ERROR)

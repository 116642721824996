package com.peerke.outdoorpuzzlegame.client.common.textparser

import kotlin.js.JsName

/**
 * Parser to parse the text in assignments, locations, etc.
 * Created by Peter on 1-5-2014.
 */
class TextParser {
    fun parse(text: String): List<TextItem> {
        return text.lines().map { line -> parseLine(line) }.flatten()
    }

    @JsName("parse")
    fun parseToArray(text: String): Array<TextItem> {
        return parse(text).toTypedArray()
    }

    private fun parseLine(text: String): MutableList<TextItem> {
        var textToParse = text
        val textItems = mutableListOf<TextItem>()

        while (textToParse.isNotEmpty()) {
            textToParse = if (textToParse.startsWith("[[")) {
                val end = textToParse.indexOf("]]")
                val tag = textToParse.substring(2, end)
                val textItem = parseTag(tag)
                textItems.add(textItem)
                textToParse.substring(end + 2, textToParse.length)
            } else if (textToParse.contains("[[") && textToParse.contains("]]")) {
                val begin = textToParse.indexOf("[[")
                textItems.add(TextTag(textToParse.substring(0, begin)))
                textToParse.substring(begin, textToParse.length)
            } else {
                textItems.add(TextTag(textToParse))
                ""
            }
        }

        return textItems
    }

    private fun parseTag(tag: String): TextItem {
        val tagParts = tag.split("|").toTypedArray()

        return parseImageTag(tagParts) ?: parseLinkTag(tagParts) ?: parseAssignmentTag(tagParts)
        ?: parseLocationTag(tagParts) ?: parseQuestionTag(tagParts) ?: UnknownTag(tag)
    }

    private fun parseImageTag(tagParts: Array<String>): ImageTag? {
        return when {
            tagParts[0] == "image" && tagParts.size == 2 -> ImageTag(tagParts[1])
            else -> null
        }
    }

    private fun parseLinkTag(tagParts: Array<String>): LinkTag? {
        return when {
            tagParts[0] == "link" && tagParts.size == 3 -> LinkTag(tagParts[1], tagParts[2])
            else -> null
        }
    }

    private fun parseAssignmentTag(tagParts: Array<String>): AssignmentTag? {
        return when {
            tagParts[0] == "assignment" && tagParts.size == 2 -> AssignmentTag(tagParts[1])
            tagParts[0] == "assignment" && tagParts.size == 4 -> AssignmentTag(
                tagParts[1],
                tagParts[2],
                tagParts[3].toBoolean())
            else -> null
        }

    }

    private fun parseLocationTag(tagParts: Array<String>): LocationTag? {
        return when {
            tagParts[0] == "location" && tagParts.size == 2 -> LocationTag(tagParts[1])
            tagParts[0] == "location" && tagParts.size == 4 -> LocationTag(
                tagParts[1],
                tagParts[2],
                tagParts[3].toBoolean())
            else -> null
        }
    }

    private fun parseQuestionTag(tagParts: Array<String>): QuestionTag? {
        return when {
            tagParts[0] == "question" && tagParts.size == 2 -> QuestionTag(tagParts[1])
            tagParts[0] == "question" && tagParts.size == 4 -> QuestionTag(tagParts[1],
                tagParts[2],
                tagParts[3].toBoolean())
            else -> null
        }
    }
}

abstract class TextItem {
    abstract fun getType(): String
}

class TextTag(val text: String) : TextItem() {
    override fun getType(): String {
        return "Text"
    }
}

class ImageTag(val fileName: String) : TextItem() {
    override fun getType(): String {
        return "Image"
    }
}

class LinkTag(
    val label: String,
    val url: String,
) : TextItem() {
    override fun getType(): String {
        return "Link"
    }
}

class AssignmentTag(
    val webKey: String,
    val name: String? = null,
    val enabled: Boolean? = null,
) : TextItem() {
    override fun getType(): String {
        return "Assignment"
    }
}

class LocationTag(
    val webKey: String,
    val name: String? = null,
    val enabled: Boolean? = null,
) : TextItem() {
    override fun getType(): String {
        return "Location"
    }
}

class QuestionTag(
    val webKey: String,
    val name: String? = null,
    val enabled: Boolean? = null,
) : TextItem() {
    override fun getType(): String {
        return "Question"
    }
}

class UnknownTag(val tag: String) : TextItem() {
    override fun getType(): String {
        return "Unknown"
    }
}